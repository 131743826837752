// import Test from './components/Test/Test.lazy';
import {
  BrowserRouter as Router
} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'primereact/resources/themes/bootstrap4-light-blue/theme.css';
import 'primeicons/primeicons.css';
import './scss/style.scss';
import Main from './components/Main/Main.lazy'

function App() {
  return (
    <Router>
      <Main/>
    </Router>
  );
}

export default App;
